import { Grid } from "@mantine/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { stateContext } from "../context/FunctionContext";
import logo from "../image/Group.png";
import { useAxiosInstance } from "../axios/useAxios";
import { format } from "timeago.js";
//
export default function Messenger() {
  const { URL_API } = useContext(stateContext);
  const [students, setStudents] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [arrayMessages, setArrayMessages] = useState([]);
  const [infoStudent, setInfoStudent] = useState(null);
  const [chooseConversation, setChooseConversation] = useState(false);
  const [selectIdOne, setSelectIdOne] = useState(null);
  const axios = useAxiosInstance();
  const scrollRef = useRef();

  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get(URL_API + "incubator/student");
        setStudents(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getConversations();
  }, []);

  const handleClick = async (id) => {
    try {
      const res = await axios.get(
        URL_API + "incubator/project-progress-message/" + id
      );
      setArrayMessages(res.data.messages.reverse());
      setInfoStudent(res.data.student);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [arrayMessages]);

  const createMessage = async () => {
    if (messageInput.length <= 1) {
      return alert("رسالة فارغة !");
    }
    try {
      const res = await axios.post(
        URL_API + "incubator/project-progress-message",
        {
          msg: messageInput,
          studentId: selectIdOne,
        }
      );
      if (res.status !== 200) {
        return "حدث خطاء خلال انشاء الرسالة";
      }
      setArrayMessages([...arrayMessages, res.data]);
      setMessageInput("");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="messenger">
      <Grid>
        <Grid.Col xs={4} sm={4} lg={3} xl={2}>
          <div className="studentsArray">
            <span style={{ fontSize: "1rem", marginBottom: "10px" }}>
              محادثات الطلاب
            </span>
            {students?.map((s, i) => (
              <div
                key={i}
                onClick={() => {
                  handleClick(s.id);
                  setChooseConversation(s.id);
                  setSelectIdOne(s.id);
                }}
                className="studendInfo"
              >
                <span>{`${
                  s.firstName + " " + s.fatherName + " " + s.grandFatherName
                }`}</span>
                <img src={s.photo} />
              </div>
            ))}
          </div>
        </Grid.Col>

        {}

        <Grid.Col xs={8} sm={8} lg={9} xl={10}>
          <div className="boxMessenger">
            <div className="top">
              <div>
                محادثتك مع :{" "}
                {infoStudent &&
                  chooseConversation &&
                  infoStudent.firstName +
                    " " +
                    infoStudent.fatherName +
                    " " +
                    infoStudent.grandFatherName}
              </div>
              {chooseConversation == false && (
                <p className="noMessages">
                  اختر محادثة للاطلاع وكتابة الرسائل .
                </p>
              )}
              {arrayMessages.map((m, i) => (
                <React.Fragment key={i}>
                  <div
                    ref={scrollRef}
                    className={`infoMessage ${
                      m?.senderType === "STUDENT" ? "fromUser" : ""
                    }`}
                  >
                    {m.senderType === "STUDENT" ? (
                      <img src={infoStudent?.photo} />
                    ) : (
                      <img src={logo} />
                    )}
                    <p>{m.msg}</p>
                  </div>
                  <span
                    className={`timeMessage ${
                      m?.senderType === "STUDENT" ? "fromUser" : ""
                    }`}
                  >
                    {format(m.createdAt)}
                  </span>
                </React.Fragment>
              ))}
            </div>

            {chooseConversation && (
              <div className="sendMessage">
                <input
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  autoFocus
                  placeholder="اكتب الرسالة"
                />
                <button onClick={createMessage}>ارسال</button>
              </div>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
}
