import { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  MantineProvider,
  createEmotionCache,
} from "@mantine/core";

import { stateContext } from "../context/FunctionContext";
import HeaderComponent from "../components/Header";
import NavbarComponent from "../components/Navbar";

import TablesStudents from "../pages/TablesStudents.jsx";
import SingleMessenger from "../pages/SingleMessenger";
import Messenger from "../pages/Messenger.jsx";

import Login from "../pages/Login";
import { theme } from "../theme";

import stylisRTLPlugin from "stylis-plugin-rtl";
import "./App.css";
import Spinner from "../components/spinner/Spinners";

export default function AppShellComponent() {
  const { openNavbar, setOpenNavbar, isLoading, token } =
    useContext(stateContext);
  const { dark } = useContext(stateContext);
  const useTheme = theme(dark);

  const rtlCache = createEmotionCache({
    key: "mantine-rtl",
    stylisPlugins: [stylisRTLPlugin],
  });

  return (
    <div className={`${dark ? "dark" : "light"}`}>
      <BrowserRouter>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={useTheme}
          emotionCache={rtlCache}
        >
          <AppShell
            navbarOffsetBreakpoint="md"
            navbar={
              token && (
                <Navbar
                  width={{ sm: 200, lg: 200 }}
                  hiddenBreakpoint="md"
                  hidden={!openNavbar}
                >
                  <NavbarComponent
                    openNavbar={openNavbar}
                    setOpenNavbar={setOpenNavbar}
                  />
                </Navbar>
              )
            }
            header={
              token && (
                <Header className="header" height={80}>
                  <MediaQuery largerThan="md" styles={{ display: "none" }}>
                    <Burger
                      opened={openNavbar}
                      onClick={() => setOpenNavbar((o) => !o)}
                      size="md"
                      mr="xl"
                    />
                  </MediaQuery>
                  <HeaderComponent />
                </Header>
              )
            }
          >
            <div className="app">
              <Routes>
                <Route path="/">
                  <Route
                    index
                    element={
                      token ? <TablesStudents /> : <Navigate to="/login" />
                    }
                  />

                  <Route
                    path="messenger"
                    element={token ? <Messenger /> : <Navigate to="/login" />}
                  />

                  <Route
                    path="messenger/:id"
                    element={
                      token ? <SingleMessenger /> : <Navigate to="/login" />
                    }
                  />

                  <Route
                    path="login"
                    element={token ? <Navigate to="/" /> : <Login />}
                  />

                  <Route
                    exact
                    path="*"
                    element={
                      <h2 style={{ textAlign: "center", margin: "5rem 0" }}>
                        Page not found ...
                      </h2>
                    }
                  />
                </Route>
              </Routes>
            </div>
          </AppShell>
        </MantineProvider>
      </BrowserRouter>

      {isLoading && <Spinner />}
    </div>
  );
}
