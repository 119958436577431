import Logo from "../image/Group.png";

const Header = () => {
  return (
    <>
      <img src={Logo} style={{ height: "40px" }} />
    </>
  );
};

export default Header;
