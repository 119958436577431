import React, { createContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
export const stateContext = createContext();

const FunctionContext = ({ children }) => {
  // urls

  const URL_API = "https://api.riyada.iq/api/";
  // const URL_API = "http://11.111.3.26:3000/api/";
  // const URL_API = "https://phpstack-912641-3237802.cloudwaysapps.com/api/";

  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("token") || null)
  );

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);

  // change and save theme color in localStorage
  const [dark, setDark] = useState(
    localStorage.getItem("darkTheme")
      ? JSON.parse(localStorage.getItem("darkTheme"))
      : false
  );
  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(dark));
  }, [dark]);

  // get info user
  const [infoUser, setInfoUser] = useState(null);
  useEffect(() => {
    token && setInfoUser(jwtDecode(token));
  }, [token]);

  //
  return (
    <stateContext.Provider
      value={{
        URL_API,
        token,
        setToken,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        isLoading,
        setIsLoading,
        dark,
        setDark,
        openNavbar,
        setOpenNavbar,
        infoUser,
      }}
    >
      {children}
    </stateContext.Provider>
  );
};

export default FunctionContext;
