import axios from "axios";
import { useContext } from "react";
import { stateContext } from "../context/FunctionContext";

export function useAxiosInstance() {
  const { token, URL_API, setToken, setIsLoading } = useContext(stateContext);

  const instance = axios.create({
    baseURL: URL_API,
    headers: {
      Authorization: token ? "Bearer " + token : null,
    },
    validateStatus: function (status) {
      return (
        (status >= 200 && status < 300) ||
        status === 400 ||
        status === 404 ||
        status === 401 ||
        status === 403 ||
        status === 500
      );
    },
  });

  instance.interceptors.request.use(
    async (config) => {
      setIsLoading(true);
      return config;
    },
    function (error) {
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use((res) => {
    if (
      (res.data.error && res.data.error === "invalidToken") ||
      res.status === 401
    ) {
      localStorage.clear();
      setToken(null);
    }
    setIsLoading(false);
    return res;
  });
  return instance;
}
