import { TextInput, Group, Box, ActionIcon } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PlusSquare, Trash2 } from "lucide-react";
import React, { useContext } from "react";
import { useAxiosInstance } from "../../axios/useAxios";
import { stateContext } from "../../context/FunctionContext";

const FinalConfirm = ({ virtualId }) => {
  const axios = useAxiosInstance();
  const { URL_API } = useContext(stateContext);

  const form = useForm({
    initialValues: {
      dates: [
        {
          date: "",
        },
      ],
    },

    validate: {
      dates: {
        date: (value) =>
          value.length < 5 ? "يرجى ادخال عنوان عقد صالح" : null,
      },
    },
  });

  const fields = form.values.dates?.map((item, index) => (
    <Group key={index} mt="xs">
      <TextInput
        withAsterisk
        type={"date"}
        sx={{ flex: 1 }}
        {...form.getInputProps(`dates.${index}.date`)}
        mb="sm"
        size="sm"
        className="datePaker"
      />

      <ActionIcon
        mb={"sm"}
        color="red"
        onClick={() => form.removeListItem("dates", index)}
      >
        <Trash2 size={20} />
      </ActionIcon>
    </Group>
  ));

  const handleClick = async () => {
    const { hasErrors } = form.validate();

    if (hasErrors) return null;

    if (form.values.dates.length < 2) {
      return alert("يرجى ادخال 2 تاريخ للعقد او اكثر");
    }
    if (window.confirm("هل انت متأكد من الموافقة على القرض ")) {
      try {
        let data = form.values.dates.map((value) => value.date);
        const res = await axios.put(
          URL_API +
            `incubator/student/${virtualId}/review-before-get-fund-from-bank`,
          {
            progressDates: data,
          }
        );
        if (res.status === 200) {
          alert("تم الموافقة على القرض");
          window.location.reload();
        }
      } catch (error) {
        alert(error.message);
        console.log(error.message);
      }
    }
  };

  return (
    <Box mx="auto">
      {fields}

      <Group position="center" mt="md">
        {form.values.dates.length > 12 ? (
          <div>هذا هو الحد الاقصى الى تواريخ العقد!</div>
        ) : (
          <button
            style={{
              display: "flex",
              alignItems: "center",
              color: "#666",
              padding: "10px",
              justifyContent: "center",
              textAlign: "center",
            }}
            onClick={() => form.insertListItem("dates", { date: "" })}
          >
            <PlusSquare style={{ marginLeft: "1rem" }} />
            اضف تاربخ متابعة
          </button>
        )}

        <button
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            borderWidth: "1px",
            borderColor: "#24AE60",
            color: "#24AE60",
            padding: "10px 1rem",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            marginTop: "2rem",
            borderRadius: "4px",
          }}
          onClick={handleClick}
        >
          اتمام
        </button>
      </Group>
    </Box>
  );
};

export default FinalConfirm;
