import { Button, Modal } from "@mantine/core";
import React, { useState } from "react";
import ConfirmContract from "./ConfirmContract";

const FormModal = ({
  data,
  virtualId,
  approvedIncubatorForm,
  incubatorContractId,
}) => {
  const [showModalTow, setShowModalTow] = useState(false);

  const getNames = (name) => {
    if (name === "rent") return "الايجار";
    if (name === "electricityCost") return "اجور الكهرباء";
    if (name === "workersCost") return "اجور العمال";
    if (name === "phoneAndInternetCost") return "اجور الهاتف والانترنيت";
    if (name === "sellingAndMarketingCost") return "تكاليف البيع والتسويق";
    if (name === "loanInstallmentValue") return "قيمة قسط القرض";
    if (name === "otherStaticCost") return "تكاليف ثابتة اخرى";
  };

  return (
    <div className="flex flex-col gap-5 " dir="rtl">
      <div className="flex flex-col gap-1">
        {/*------------------------------------------------------------------------اسم المشروع------------------------------------------------------------------------*/}
        <label className="text-lg font-semibold text-gray-800">
          اسم المشروع
        </label>
        <p className="text-sm  text-gray-500">{data.projectName}</p>
      </div>
      <div className="flex flex-col gap-1">
        {/*------------------------------------------------------------------------نوع المشروع------------------------------------------------------------------------*/}
        <label className="text-lg font-semibold text-gray-800">
          نوع المشروع
        </label>
        <p className="text-sm text-gray-500">{data.projectType}</p>
      </div>

      <hr />
      {/*------------------------------------------------------------------------التكاليف الثابتة------------------------------------------------------------------------*/}
      <div className="flex flex-col gap-2">
        <label className="text-lg font-semibold text-gray-800">
          التكاليف الثابتة
        </label>
        <div className="ext-gray-500 gap-5 flex flex-col">
          {data?.incubatorStaticCost?.map((c, index) => (
            <div key={index} className="flex flex-col">
              <div className="text-md font-semibold text-gray-800">
                {index + 1}- {getNames(c.staticMaterials)}
              </div>

              <div className="px-4 text-sm">
                <div className="flex gap-2 mt-1 ">
                  <div className="font-semibold text-gray-600">الكلفة:</div>
                  <div>{c.monthlyValue} </div>
                </div>

                <div className="flex gap-2">
                  <div className="font-semibold text-gray-600">الملاحظات:</div>
                  <div>{c.note}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <hr />
      {/*------------------------------------------------------------------------المبيعات المتوقعة شهريا------------------------------------------------------------------------*/}
      <div className="flex flex-col gap-2">
        <label className="text-lg font-semibold text-gray-800">
          المبيعات المتوقعة شهريا
        </label>
        {/*<p className="font-semibold text-md text-gray-600">المنتج المباع</p>*/}
        <div className="  flex flex-col gap-5">
          {data?.theAmountOfExpectedSalesPerMonth?.map((c, index) => (
            <div key={index} className="flex flex-col gap-1">
              <div className="flex gap-2 text-md text-gray-600">
                <div className="font-semibold text-gray-600">
                  {index + 1}- اسم المنتج:
                </div>
                <div>{c.theSoldProduct}</div>
              </div>

              <div className="px-3 text-sm flex flex-col gap-1">
                <div className="flex gap-1">
                  <div className="font-semibold text-gray-600">
                    كلفة المواد الاولية:
                  </div>
                  <div>{c.rawMaterialsCost} </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold text-gray-600">سعر البيع:</div>
                  <div>{c.sellingPrice} </div>
                </div>

                <div className="flex gap-1">
                  <div className="font-semibold text-gray-600">
                    سعر القطعة مطروحا منه المواد الاولية:
                  </div>
                  <div>{c.sellingPriceMinesRawMaterialsCost} </div>
                </div>

                <div className="flex gap-1">
                  <div className="font-semibold text-gray-600">
                    عدد القطع المباعة شهريا:
                  </div>
                  <div>{c.monthlySales} </div>
                </div>

                <div className="flex gap-1">
                  <div className="font-semibold text-gray-600">
                    المبلغ الصافي:
                  </div>
                  <div>{c.theNetAmount} </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex gap-2 px-4 mt-2">
          <div className="font-semibold">صافي الارباح</div>
          <div className="font-semibold">{data.netProfit} </div>
        </div>
      </div>

      <hr />
      {/*------------------------------------------------------------------------متطلبات المشروع------------------------------------------------------------------------*/}
      <div className="flex flex-col">
        <label className="text-lg font-semibold text-gray-800">
          متطلبات المشروع
        </label>

        <div className="text-sm text-gray-500 flex flex-col gap-5 mt-2">
          {data?.projectRequirements?.map((r, index) => {
            return (
              <div key={index} className="flex flex-col">
                <div className="flex gap-1">
                  <label className="font-semibold text-gray-600">
                    اسم المادة:
                  </label>

                  <div>{r.materialName}</div>
                </div>

                <div className="flex gap-1">
                  <label className="font-semibold text-gray-600">
                    قيمة المبلغ:
                  </label>

                  <div>{r.amountValue}</div>
                </div>

                <div className="flex gap-1">
                  <label className="font-semibold text-gray-600">
                    الملاحظات:
                  </label>

                  <div>{r.note}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/*------------------------------------------------------------------------المبلغ الكلي للقرض------------------------------------------------------------------------*/}
      <div className="flex gap-1 font-semibold">
        <label className="">المبلغ الكلي للقرض:</label>
        <p>{data.totalAmountOfTheLoan} </p>
      </div>

      {approvedIncubatorForm ? (
        <Button h={"50px"} disabled fz={"1.2rem"} variant="riyada">
          تم الموافقة على القرض
        </Button>
      ) : incubatorContractId != null ? (
        <Button h={"50px"} disabled fz={"1.2rem"} variant="riyada">
          يرجى انتظار موافقة الطالب
        </Button>
      ) : (
        <Button
          h={"50px"}
          onClick={() => setShowModalTow(true)}
          fz={"1.2rem"}
          variant="riyada"
        >
          تاكيد العقد
        </Button>
      )}

      <Modal
        dir="rtl"
        padding={50}
        size={600}
        opened={showModalTow}
        onClose={() => setShowModalTow(false)}
        overlayColor="#333"
        overlayOpacity={1}
        centered
      >
        <ConfirmContract virtualId={virtualId} />
      </Modal>
    </div>
  );
};

export default FormModal;
