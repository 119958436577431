import { Grid } from "@mantine/core";
import React, { useContext } from "react";
import { stateContext } from "../../context/FunctionContext";

const DisplayFiles = ({ data }) => {
  const { URL_API } = useContext(stateContext);

  return (
    <div>
      {data.ProjectProgress.map((item, index) => (
        <div
          key={index}
          style={{
            border: "solid 1px #ddd",
            borderRadius: "4px",
            padding: "1rem",
            marginBottom: "2rem",
          }}
        >
          <p style={{ fontWeight: "bold", margin: "1rem 0" }}>
            {index + 1} - تم رفعه بتاريخ ،{" "}
            {new Date(item.createdAt).toLocaleDateString()}
          </p>
          <p>{item.description}</p>

          <Grid>
            {item.files.map((file, idx) =>
              file.split(".").pop() === "mp4" ||
              file.split(".").pop() === "mov" ? (
                <Grid.Col key={idx} sm={12}>
                  <video width="100%" height="240" controls>
                    <source src={URL_API + "image/" + file} />
                  </video>
                </Grid.Col>
              ) : (
                <Grid.Col key={idx} sm={3} md={3} lg={4}>
                  <img
                    loading="lazy"
                    style={{
                      maxHeight: "500px",
                      objectFit: "cover",
                      margin: "2rem 0",
                    }}
                    src={URL_API + "image/" + file}
                  />
                </Grid.Col>
              )
            )}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default DisplayFiles;
