import { useAxiosInstance } from "../axios/useAxios";
import { stateContext } from "../context/FunctionContext";
import { Button, TextInput, Image, Box, Paper, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notification } from "@mantine/core";
import { LogIn } from "lucide-react";
import logo from "../image/Group.png";
import * as yup from "yup";
import OtpInput from "react18-input-otp";
import CountDown from "../components/CountDwonTimr";
//
const LoginComponent = () => {
  const { setToken } = useContext(stateContext);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  // state pages
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(null);

  // state first step
  const [tokenExpired, setTokenExpired] = useState(null);

  // state secode step
  const [otp, setOtp] = useState("");
  const [otpToken, setOtpToken] = useState("");

  const validatePhoneNumber = yup.object().shape({
    phone: yup
      .string()
      .min(10, "يرجى ادخال رقم صالح للاستخدام")
      .max(15, "يرجى ادخال رقم صالح للاستخدام")
      .required("الحقل مطلوب"),
  });

  const validatePassword = yup.object().shape({
    password: yup
      .string()
      .min(8, "كلمة المرور يجب ان تكون على الاقل ٨ حروف او ارقام")
      .required("الحقل مطلوب"),
  });

  const validateBoth = yup.object().shape({
    phone: yup
      .string()
      .min(10, "يرجى ادخال رقم صالح للاستخدام")
      .max(15, "يرجى ادخال رقم صالح للاستخدام")
      .required("الحقل مطلوب"),
    password: yup
      .string()
      .min(8, "كلمة المرور يجب ان تكون على الاقل ٨ حروف او ارقام")
      .required("الحقل مطلوب"),
  });

  const form = useForm({
    initialValues: { phone: "", password: "" },
    validate:
      index === 0
        ? yupResolver(validatePhoneNumber)
        : index === 2
        ? yupResolver(validatePassword)
        : index === 3
        ? yupResolver(validateBoth)
        : "",
  });

  const sentOtp = async () => {
    try {
      const res = await axiosInstance.post("auth/sentotp", {
        phone: form.values.phone,
      });
      if (res.data.error === "invalidInput") {
        return alert("يرجى ادخال رقم هاتف صالح للاستخدام");
      }

      const dateNow = new Date();
      const newDate =
        new Date(res.data.expireTime).getTime() - dateNow.getTime();
      const finalDate = newDate / 1000;
      setTokenExpired(finalDate);
      setIndex(1);
    } catch (error) {
      setError(error.message);
      console.log(error.message);
    }
  };

  const verfiyOtp = async () => {
    if (otp.length < 5) {
      return alert("يرجى ادخال رقم الشفرة كامل");
    }
    try {
      const res = await axiosInstance.post("auth/verifyotp", {
        phone: form.values.phone,
        otpCode: otp,
      });
      if (res.data.error === "InvalidOtp" || res.status !== 200) {
        return alert("رقم الشفرة خاطئ");
      }

      setOtpToken(res.data.otpToken);
      setIndex(2);
    } catch (error) {
      console.log(error.message);
    }
  };

  const createAccount = async () => {
    try {
      const res = await axiosInstance.post(
        "auth/createaccount",
        {
          userType: "INCUBATOR",
          password: form.values.password,
        },
        { headers: { Authorization: "Bearer " + otpToken } }
      );

      if (res.data.error === "alreadyExists") {
        alert("الحساب موجود بلفعل يرجى تسجيل الدخول");
        return setIndex(3);
      }

      if (res.data.error === "WrongUserType") {
        return alert("يرجى التسجيل في استمارة المدرب اولاً ");
      }

      if (res.status !== 200) {
        return alert("شيءً ما خاطئ");
      }

      setToken(res.data.accessToken);
      localStorage.setItem("token", JSON.stringify(res.data.accessToken));
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const Login = async () => {
    try {
      const res = await axiosInstance.post("auth/login", {
        phone: form.values.phone,
        password: form.values.password,
      });
      if (res.data.error === "InvalidOtp" || res.status !== 200) {
        return alert("رقم الهاتف او كلمة المرور خاطئة");
      }

      setToken(res.data.accessToken);
      localStorage.setItem("token", JSON.stringify(res.data.accessToken));
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box
      h={"100vh"}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        withBorder
        style={{ borderRadius: "15px" }}
        w="500px"
        className="login"
      >
        <div className="top">
          {index === 1 ? (
            <CountDown tokenExpired={tokenExpired} />
          ) : (
            <Image mb="md" src={logo} fit="cover" height={80} width={80} />
          )}
        </div>
        <form
          onSubmit={form.onSubmit(
            index === 0
              ? sentOtp
              : index === 1
              ? verfiyOtp
              : index === 2
              ? createAccount
              : Login
          )}
        >
          {index === 0 ? (
            <React.Fragment>
              <TextInput
                withAsterisk
                size="md"
                mt="lg"
                label="رقم الهاتف"
                placeholder="..."
                {...form.getInputProps("phone")}
              />

              <Button
                className="button"
                leftIcon={<LogIn />}
                size={"lg"}
                type="submit"
                variant="riyada"
              >
                الخطوة التالية
              </Button>

              <Text
                onClick={() => setIndex(3)}
                style={{
                  cursor: "pointer",
                  marginTop: "1rem",
                  color: "#666",
                }}
              >
                هل لديك حساب؟ تسجيل الدخول .
              </Text>
            </React.Fragment>
          ) : index === 1 ? (
            <React.Fragment>
              <Text>يرجى ادخال الشفرة المرسلة الى رقم هاتفك</Text>

              <div className="parentOtp">
                <OtpInput
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  numInputs={6}
                  className="otp"
                  separator={
                    <span
                      style={{
                        margin: "0",
                        padding: "0",
                        color: "#24AE60",
                      }}
                    ></span>
                  }
                />
              </div>

              <Button
                className="button"
                leftIcon={<LogIn />}
                size={"lg"}
                type="submit"
                variant="riyada"
              >
                تحقق
              </Button>
            </React.Fragment>
          ) : index === 2 ? (
            <React.Fragment>
              <TextInput
                size="md"
                type={"password"}
                mt="lg"
                label="انشاء كلمة مرور لحسابك"
                {...form.getInputProps("password")}
                withAsterisk
              />
              <Button
                className="button"
                leftIcon={<LogIn />}
                size={"lg"}
                type="submit"
                variant="riyada"
              >
                تسجيل الدخول
              </Button>
            </React.Fragment>
          ) : index === 3 ? (
            <React.Fragment>
              <TextInput
                withAsterisk
                size="md"
                mt="lg"
                label="رقم الهاتف"
                placeholder=" رقم هاتف "
                {...form.getInputProps("phone")}
              />

              <TextInput
                size="md"
                type={"password"}
                mt="lg"
                label=" كلمة مرور"
                placeholder=" كلمة مرور "
                {...form.getInputProps("password")}
                withAsterisk
              />
              <Button
                className="button"
                leftIcon={<LogIn />}
                size={"lg"}
                type="submit"
                variant="riyada"
              >
                تسجيل الدخول
              </Button>
            </React.Fragment>
          ) : (
            ""
          )}

          {error && (
            <Notification color="red" mt={"sm"}>
              {error}
            </Notification>
          )}
        </form>
      </Paper>
    </Box>
  );
};

export default LoginComponent;
