import { Pagination } from "@mantine/core";
import React from "react";

const ComponentPagination = ({ currentPage, setCurrentPage, totalPages }) => {
  return (
    <Pagination
      total={totalPages}
      onChange={setCurrentPage}
      page={currentPage}
      size="md"
      mt="lg"
      mb="lg"
      dir="ltr"
      position="right"
      color={"teal"}
    />
  );
};

export default ComponentPagination;
