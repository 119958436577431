import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function CountDown({ tokenExpired }) {
  //
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      // window.location.reload();
    }

    return (
      <div className="timer">
        <div style={{ color: "gray", fontSize: "12px" }}>
          الوقت الصالح للشفرة
        </div>
        <div className="value">{remainingTime}</div>
        <div style={{ color: "gray", fontSize: "12px" }} className="text">
          ثواني
        </div>
      </div>
    );
  };

  return (
    <div className="timerWrapper">
      <CountdownCircleTimer
        isPlaying
        duration={tokenExpired}
        colors={"#24AE60"}
        onComplete={() => ({ shouldRepeat: false, delay: 1 })}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
}
