import { Grid } from "@mantine/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { stateContext } from "../context/FunctionContext";
import logo from "../image/Group.png";
import { useAxiosInstance } from "../axios/useAxios";
import { format } from "timeago.js";
import { useLocation } from "react-router-dom";
//
export default function Messenger() {
  const { URL_API } = useContext(stateContext);
  const [messageInput, setMessageInput] = useState("");
  const [arrayMessages, setArrayMessages] = useState([]);
  const [infoStudent, setInfoStudent] = useState(null);
  const axios = useAxiosInstance();
  const scrollRef = useRef();
  const { state } = useLocation();

  useEffect(() => {
    const getSingleChat = async () => {
      try {
        const res = await axios.get(
          URL_API + "incubator/project-progress-message/" + state + ""
        );

        setArrayMessages(res.data.messages.reverse());
        setInfoStudent(res.data.student);
      } catch (error) {
        console.log(error.message);
      }
    };
    getSingleChat();
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [arrayMessages]);

  const createMessage = async () => {
    if (messageInput.length <= 1) {
      return alert("رسالة فارغة !");
    }
    try {
      const res = await axios.post(
        URL_API + "incubator/project-progress-message",
        {
          msg: messageInput,
          studentId: state,
        }
      );
      if (res.status !== 200) {
        return "حدث خطاء خلال انشاء الرسالة";
      }
      setArrayMessages([...arrayMessages, res.data]);
      setMessageInput("");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="messenger">
      <Grid justify={"center"}>
        <Grid.Col xs={10}>
          <div className="boxMessenger">
            <div className="top">
              <div>
                محادثتك مع :{" "}
                {infoStudent?.firstName &&
                  infoStudent?.firstName +
                    " " +
                    infoStudent?.fatherName +
                    " " +
                    infoStudent?.grandFatherName}
              </div>
              {arrayMessages.map((m, i) => (
                <React.Fragment key={i}>
                  <div
                    ref={scrollRef}
                    className={`infoMessage ${
                      m?.senderType === "STUDENT" ? "fromUser" : ""
                    }`}
                  >
                    {m.senderType === "STUDENT" ? (
                      <img src={infoStudent?.photo} />
                    ) : (
                      <img src={logo} />
                    )}
                    <p>{m.msg}</p>
                  </div>
                  <span
                    className={`timeMessage ${
                      m?.senderType === "STUDENT" ? "fromUser" : ""
                    }`}
                  >
                    {format(m.createdAt)}
                  </span>
                </React.Fragment>
              ))}
            </div>

            <div className="sendMessage">
              <input
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                autoFocus
                placeholder="اكتب الرسالة"
              />
              <button onClick={createMessage}>ارسال</button>
            </div>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
}
