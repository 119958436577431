//
export const theme = (dark) => {
  const theme = {
    colorScheme: dark ? "dark" : "light",
    fontFamily: "SomarSansRegular",
    components: {
      Button: {
        styles: (theme, params) => ({
          root: {
            height: 42,
            padding: "0 30px",
            backgroundColor:
              params.variant === "filled"
                ? theme.colors[params.color || theme.primaryColor][9]
                : params.variant === "riyada"
                ? "#31C48D"
                : params.variant === "test"
                ? "#444"
                : undefined,
            "&:hover": {
              backgroundColor: theme.fn.darken("#31C48D", 0.05),
            },
          },
        }),
      },
    },
  };

  return theme;
};
