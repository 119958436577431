import { Button, Checkbox, Modal, Text } from "@mantine/core";
import React, { useState } from "react";
import FinalConfirm from "./FinalConfirm";

const ConfirmContract = ({ virtualId }) => {
  const [checkbox, setCheckBox] = useState(false);
  const [showModalThree, setShowModalThree] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.2rem",
        borderColor: "#24AE60",
        borderWidth: "1px",
        padding: " 2rem",
        paddingTop: "10px",
        borderRadius: "4px",
      }}
    >
      <Text fz={"1.6rem"} sx={{ textAlign: "center" }}>
        عقد الحاضنات{" "}
      </Text>
      <Text>
        1- مدة المتابعة للمشروع سنة واحدة من تأريخ التوقيع على هذا العقد .
      </Text>
      <Text>
        2- يقوم الطرف الأول بالمساهمة في إنجاح وتطوير وتنمية مشروع الطرف الثاني
        من خلال تقديم الارشاد والنصح وتبادل الزيارات حسب جدول متفق عليه
      </Text>
      <Text>
        3- يلتزم الطرف الثاني باستقبال الطرف الأول أثناء الزيارات والحضور في
        الورش التنموية لاكتساب المهارات اللازمة لتحسين الاداء الاداري.
      </Text>
      <Text>
        4- يرفع الطرف الاول ملاحظاته عن تقدم العمل من عدمه في التطبيق. مخطط
        المتابعة (ملحق)
      </Text>

      <Checkbox
        onChange={(e) => setCheckBox(e.currentTarget.checked)}
        label="انا اوافق على جميع الشروط المذكورة اعلاه"
        radius="xs"
        color={"teal"}
        size="md"
      />

      <button
        style={{
          padding: "10px",
          backgroundColor: "transparent",
          color: "#24AE60",
          borderColor: "#24AE60",
          borderWidth: "1px",
          marginTop: "2rem",
        }}
        onClick={() => {
          if (!checkbox) {
            return alert("يرجى الموافقة على شروط العقد اولاً");
          }

          setShowModalThree(true);
        }}
      >
        الخطوة التالية
      </button>

      <Modal
        dir="rtl"
        padding={50}
        size={600}
        opened={showModalThree}
        onClose={() => setShowModalThree(false)}
        centered
        overlayColor="#333"
        overlayOpacity={1}
      >
        <FinalConfirm virtualId={virtualId} />
      </Modal>
    </div>
  );
};

export default ConfirmContract;
